import React, { Suspense } from 'react';
// import MainPage from './components/MainPage/MainPage';
import MainPageContext from './contexts/mainPageContext';
import data from './constants/mainPageContextData';
import Spinner from './components/UI/Spinner/Spinner';
import './App.css';

const MainPage = React.lazy(() => import('./components/MainPage/MainPage'));

const App: React.FC = () => (
  <div className="App">
    <MainPageContext.Provider value={data}>
      <Suspense
        fallback={(
          <div className="spinnerBg">
            <Spinner />
          </div>
        )}
      >
        <MainPage />
      </Suspense>
    </MainPageContext.Provider>
  </div>
);

export default App;
