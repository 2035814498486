import { createContext } from 'react';

type benefitsSlides = {
  title: string;
  text: string;
  imgSrc: string;
  imgAlt: string;
}[];

type portfolioSlides = {
  projName: string;
  imgSrc: string;
  imgSrcAlt: string;
  imgAlt: string;
  cardTitle: string;
  technologiesUsed: string[];
  aboutProject: string;
}[];

interface ContextTypes {
  benefitsSlides: benefitsSlides;
  portfolioSlides: portfolioSlides;
}

const mainPageContext = createContext({} as ContextTypes);

export default mainPageContext;
