import clientsImg from '../images/benefitsImages/clients.png';
import leadershipImg from '../images/benefitsImages/leadership.png';
import progressImg from '../images/benefitsImages/progress.png';
import mezzoSlide from '../images/carouselImages/mezzoSlide.svg';
import mobileMezzoSlide from '../images/carouselImages/mobileMezzoSlide.png';
import atoirSlide from '../images/carouselImages/atoirSlide.png';
import mobileAtoirSlide from '../images/carouselImages/mobileAtoirSlide.png';
import abcSlide from '../images/carouselImages/abcSlide.png';
import mobileAbcSlide from '../images/carouselImages/mobileAbcSlide.png';

const data = {
  benefitsSlides: [
    {
      title: '',
      text:
        'We have an international development team.'
        + ' We share experience and believe in the power of collaboration.'
        + ' Our agile development process ensures that we can adapt quickly to changing requirements'
        + ' and deliver solutions that exceed expectations.',
      imgSrc: clientsImg,
      imgAlt: 'Clients',
    },
    {
      title: '',
      text:
        ' We are proud to have a team of highly skilled professionals who possess deep knowledge in their'
        + ' respective areas of expertise. Our team members are not only experts in their fields but also have'
        + ' a strong sense of ownership and responsibility for the entire solution',
      imgSrc: leadershipImg,
      imgAlt: 'Leadership',
    },
    {
      title: '',
      text:
        ' We understand the importance'
        + ' of staying up-to-date with the latest technologies and trends, as well as ongoing development '
        + 'of personal and professional skills. To support our team members in their pursuit of growth, we have'
        + ' established a program of co-financing for training in professional courses, participation in conferences,'
        + ' and English courses. ',
      imgSrc: progressImg,
      imgAlt: 'Progress',
    },
  ],
  portfolioSlides: [
    {
      projName: 'M',
      imgSrc: mezzoSlide,
      imgSrcAlt: mobileMezzoSlide,
      imgAlt: 'M',
      cardTitle: 'Smart Home solution',
      technologiesUsed: ['iOS', 'Android', 'Web'],
      aboutProject: 'Managing life support systems at home',
    },
    {
      projName: 'Atoir',
      imgSrc: atoirSlide,
      imgSrcAlt: mobileAtoirSlide,
      imgAlt: 'Atoir',
      cardTitle: 'Атоир',
      technologiesUsed: ['Android', 'Web'],
      aboutProject: 'Equipment repair control system',
    },
    {
      projName: 'Азбукварик',
      imgSrc: abcSlide,
      imgSrcAlt: mobileAbcSlide,
      imgAlt: 'Азбукварик',
      cardTitle: 'Азбукварик',
      technologiesUsed: ['iOS', 'Android', 'Web'],
      aboutProject: 'Book publishing app',
    },
  ],
};

export default data;
